import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _vm.serviceType === "DELETE"
        ? _c(
            VCardTitle,
            { staticClass: "H4-Secondary-Center dropoff-title" },
            [_vm._v("Delete Marketplace")]
          )
        : _vm._e(),
      _vm.serviceType === "ENABLE"
        ? _c(
            VCardTitle,
            { staticClass: "H4-Secondary-Center dropoff-title" },
            [_vm._v("Enable Marketplace")]
          )
        : _vm._e(),
      _c(
        VCardText,
        [
          _c(VFlex, { attrs: { xs12: "" } }, [
            _vm.serviceType === "DELETE"
              ? _c("p", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
                  _vm._v(
                    "\n        Stations within\n        " +
                      _vm._s(
                        _vm.locationObject && _vm.locationObject.meta
                          ? _vm.locationObject.meta.unit
                          : "the cost center"
                      ) +
                      "\n        will no longer display as a market, but rather as individual stations. Deleting this\n        marketplace does not save your settings and cannot be undone.\n      "
                  )
                ])
              : _vm._e(),
            _vm.serviceType === "ENABLE"
              ? _c("p", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
                  _vm._v(
                    "\n        Stations within\n        " +
                      _vm._s(
                        _vm.locationObject && _vm.locationObject.meta
                          ? _vm.locationObject.meta.unit
                          : "the cost center"
                      ) +
                      "\n        will no longer display as individual stations, but instead as a Marketplace and some\n        options will be overriden. Also note that marketplace does not support delivery at this\n        time.\n      "
                  )
                ])
              : _vm._e()
          ]),
          _c(VFlex, { attrs: { xs12: "" } }, [
            _vm.serviceType === "DELETE"
              ? _c("p", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
                  _vm._v(
                    "\n        To continue please enter DELETE in the field below.\n      "
                  )
                ])
              : _vm._e(),
            _vm.serviceType === "ENABLE"
              ? _c("p", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
                  _vm._v(
                    "\n        To continue please enter ENABLE in the field below.\n      "
                  )
                ])
              : _vm._e()
          ]),
          _c(VFlex, { attrs: { xs8: "" } }, [
            _c(
              "div",
              [
                _c(
                  VForm,
                  { ref: "confirmationRef" },
                  [
                    _c(VTextField, {
                      attrs: {
                        label: _vm.textfieldLabelType,
                        outline: "",
                        flat: "",
                        rules: _vm.rules
                      },
                      model: {
                        value: _vm.confirmationString,
                        callback: function($$v) {
                          _vm.confirmationString = $$v
                        },
                        expression: "confirmationString"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(VLayout)
        ],
        1
      ),
      _c(
        VCardActions,
        [
          _c(VSpacer),
          _c(
            VBtn,
            {
              attrs: { color: "primary", flat: "" },
              on: { click: _vm.handleDialogCancel }
            },
            [_vm._v("cancel")]
          ),
          _c(
            VBtn,
            {
              attrs: { color: "primary", flat: "" },
              on: { click: _vm.deleteMarketplace }
            },
            [_vm._v("continue")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }