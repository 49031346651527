<template>
  <v-card>
    <v-card-title class="H4-Secondary-Center dropoff-title" v-if="serviceType === 'DELETE'"
      >Delete Marketplace</v-card-title
    >
    <v-card-title class="H4-Secondary-Center dropoff-title" v-if="serviceType === 'ENABLE'"
      >Enable Marketplace</v-card-title
    >
    <v-card-text>
      <v-flex xs12>
        <p class="Body-1-Black-High-Emphasis-Left" v-if="serviceType === 'DELETE'">
          Stations within
          {{ locationObject && locationObject.meta ? locationObject.meta.unit : 'the cost center' }}
          will no longer display as a market, but rather as individual stations. Deleting this
          marketplace does not save your settings and cannot be undone.
        </p>
        <p class="Body-1-Black-High-Emphasis-Left" v-if="serviceType === 'ENABLE'">
          Stations within
          {{ locationObject && locationObject.meta ? locationObject.meta.unit : 'the cost center' }}
          will no longer display as individual stations, but instead as a Marketplace and some
          options will be overriden. Also note that marketplace does not support delivery at this
          time.
        </p>
      </v-flex>
      <v-flex xs12>
        <p class="Body-1-Black-High-Emphasis-Left" v-if="serviceType === 'DELETE'">
          To continue please enter DELETE in the field below.
        </p>
        <p class="Body-1-Black-High-Emphasis-Left" v-if="serviceType === 'ENABLE'">
          To continue please enter ENABLE in the field below.
        </p>
      </v-flex>
      <v-flex xs8>
        <div>
          <v-form ref="confirmationRef">
            <v-text-field
              v-model="confirmationString"
              :label="textfieldLabelType"
              outline
              flat
              :rules="rules"
            ></v-text-field>
          </v-form>
        </div>
      </v-flex>
      <v-layout> </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" flat @click="handleDialogCancel">cancel</v-btn>
      <v-btn color="primary" flat @click="deleteMarketplace">continue</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/rules';

export default {
  props: {
    location: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    serviceType: {
      // either DELETE or ENABLE for type
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    confirmationString: '',
  }),
  computed: {
    textfieldLabelType() {
      if (this.serviceType === 'DELETE') {
        return 'Confirm Delete';
      }
      return 'Confirm Enable Marketplace';
    },
    locationObject() {
      if (this.location) {
        return this.location;
      }
      return null;
    },
    rules() {
      const confirmText = this.serviceType === 'DELETE' ? 'DELETE' : 'ENABLE';
      const incorrectString = (v) => {
        if (v && v.toUpperCase() !== confirmText) {
          return `Please enter ${confirmText} to continue`;
        }

        return true;
      };

      return [rules.required('Confirmation cannot be left empty.'), incorrectString];
    },
  },
  methods: {
    deleteMarketplace() {
      if (this.$refs.confirmationRef.validate()) {
        this.handleDialogSave();
      }
      return null;
    },

    handleDialogCancel() {
      this.$emit('dismissDialog');
      this.$refs.confirmationRef.reset();
      return null;
    },
    handleDialogSave() {
      this.$emit('handleMarketplaceStatusChange');
      this.$emit('dismissDialog');
      this.$refs.confirmationRef.reset();
      return null;
    },
  },
};
</script>
